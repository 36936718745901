import { Button, makeStyles } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo3 from "../assets/Aquant_Logo_Main_White.svg";
import { Avatar, Drawer, List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu'
import PublishIcon from '@material-ui/icons/Publish';
import DescriptionIcon from '@material-ui/icons/Description';
import BuildIcon from '@material-ui/icons/Build';
import { Operations } from '../Operations';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from "react-router-dom";
import { MainStore } from '../stores/MainStore'
import FileSaver from 'file-saver'
import { useFlags } from 'launchdarkly-react-client-sdk';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { LEGACY_MODE } from '../App';
import { Settings } from '../Settings'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#123241',
    opacity: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: 'white'
  },
  logo: {
    maxWidth: 130,
    marginRight: '10px'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 1,
  },
  drawerPaper: {
    backgroundColor: '#ECEDEF',
    width: drawerWidth,
    marginTop: 65,
    height: 'calc(100% - 65px - 65px)'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  list: {
    width: 250,
  },
  menuIcon: {
    marginRight: '8px',
  }
}));

type AppBarProps = {
  mainStore: MainStore
  onLogout: () => void,
}

// eslint-disable-next-line no-empty-pattern
export const MyAppBar = ({ mainStore, onLogout }: AppBarProps) => {
  const [appSettings, setAppSettings] = useState<Settings>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await mainStore.getSettings()
        setAppSettings(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); 
  
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<Element>()
  const localMode = appSettings?.env.REACT_APP_SERVER_URL === "http://localhost:8000"
  const history = useHistory()
  const flags = useFlags()
  const { user, securityStore, MetadataFile } = mainStore;


  const closeAll = () => {
    setDrawerOpen(false)
    setAnchorEl(undefined)
  }

  const registerCloseAll = (e: React.MouseEvent) => {
    e.stopPropagation()
    document.addEventListener("click", closeAll, {once: true})
  }

  const openDrawer = (e: React.MouseEvent) => 
  {
      setDrawerOpen(true)
      registerCloseAll(e)
  }

  const openMenu = (e: React.MouseEvent) => 
  {
      setAnchorEl(e.currentTarget)
      registerCloseAll(e)
  }

  const toggleDrawer = (event: React.KeyboardEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(false)
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={toggleDrawer}
    >
      <List>
        <ListItem button onClick={() => history.push('/')}>
          <HomeIcon className={classes.menuIcon}/>
          <ListItemText primary="Home" />
        </ListItem>
        {securityStore && securityStore.isAllowed(Operations.UnifiedRoute) && <ListItem button onClick={() => history.push('/alt')}>
          <HomeIcon className={classes.menuIcon}/>
          <ListItemText primary="Alt Home" />
        </ListItem>}
        {securityStore && securityStore.isAllowed(Operations.ManualInjection) && <ListItem button onClick={() => history.push('/manual')}>
          <PublishIcon className={classes.menuIcon}/>
          <ListItemText primary="Manual Injection" />
        </ListItem>}
        {securityStore && securityStore.isAllowed(Operations.ManualInjection) && <ListItem button onClick={() => history.push('/finalizer')}>
          <FolderZipIcon className={classes.menuIcon}/>
          <ListItemText primary="Finalizer" />
        </ListItem>}
        {securityStore && securityStore.isAllowed(Operations.EditTemplate) && <ListItem button onClick={() => history.push('/template')}>
          <BuildIcon className={classes.menuIcon}/>
          <ListItemText primary="Template Editor" />
        </ListItem>}
        {securityStore && securityStore.isAllowed(Operations.InjectMetadata) &&
         <ListItem button onClick={() => history.push('/metadata')}> 
          <DescriptionIcon className={classes.menuIcon}/>
          <ListItemText primary="Compute Metadata" />
          </ListItem>}
        {securityStore && securityStore.isAllowed(Operations.CollectCustomersDs) && flags.collectCustomersLatestDataset && <ListItem button onClick={() => history.push('/collect')}>
          <BuildIcon className={classes.menuIcon}/>
          <ListItemText primary="Collect Customers DS" />
        </ListItem>}
        {securityStore && securityStore.isAllowed(Operations.DigestWizard) && <ListItem button onClick={() => history.push('/digest')}>
          <BuildIcon className={classes.menuIcon}/>
          <ListItemText primary="Digest Wizard" />
        </ListItem>}
        {<ListItem button onClick={() => history.push('/copilot')}>
          <BuildIcon className={classes.menuIcon}/>
          <ListItemText primary="Service Co-Pilot Onboarding" />
        </ListItem>}
        {securityStore && securityStore.isAllowed(Operations.UserManager) && <ListItem button onClick={() => history.push('/usermanager')}>
          <BuildIcon className={classes.menuIcon}/>
          <ListItemText primary="Salesforce User Manager" />
        </ListItem>}
        {!LEGACY_MODE && mainStore.LegacyPackageVersion && <ListItem button component='a' href='https://aq-si-fe-legacy.herokuapp.com/'>
          <FastRewindIcon className={classes.menuIcon}/>
          <ListItemText primary={`Legacy V.${mainStore.LegacyPackageVersion}`} />
        </ListItem>}
        {LEGACY_MODE && <ListItem button component='a' href='https://aq-si-fe.herokuapp.com/'>
          <FastForwardIcon className={classes.menuIcon}/>
          <ListItemText primary={`Latest V.${process.env.REACT_APP_LATEST_VERSION}`} />
        </ListItem>}
      </List>
    </div>
  );

  const tool_bar_style  = localMode ? {backgroundColor: 'orange'} : {}
  const button_style = {
    width: "103px",
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(0, 0, 0, 0.54)",
    boxShadow: "rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px",
    borderRadius: "2px",
    border: "1px solid transparent",
  }

  return (<AppBar position='sticky' className={classes.appBar}>
    <Toolbar style={tool_bar_style}>
      <IconButton edge="start" className={classes.menuButton} color='secondary' aria-label="menu" onClick={openDrawer}>
        <MenuIcon style={{ color: 'white' }} />
      </IconButton>
      <img src={logo3} alt="AquantLogo" className={classes.logo} />
      <Typography className={classes.title}>
      {'Service Insights Databridge'}
      {localMode && '  - Local Mode  '}
      {localMode && <BuildIcon fontSize='small'/>}
      </Typography>
      {user && <Avatar alt={user!.profile.name} src={user!.profile.imageUrl} onClick={openMenu}></Avatar>}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          {list()}
        </div>
      </Drawer>
      <Menu
        id="avatar-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={anchorEl !== undefined}
      >
        {MetadataFile &&
        <MenuItem>
          <Button variant="contained"
                  style={button_style}
                  disableElevation
                  onClick={async () => {
                    FileSaver.saveAs(await mainStore.getDownloadUrl(MetadataFile.url), 'metadata.json')
                    await mainStore.fetchExistingFiles()
                }}>Metadata</Button>
        </MenuItem>}
        <MenuItem onClick={() => setAnchorEl(undefined)}>
        <Button variant="contained"
                  style={button_style}
                  disableElevation
                  onClick={onLogout}>Logout</Button>
        </MenuItem>
      </Menu>
    </Toolbar>
  </AppBar>)
}